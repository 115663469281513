/**
 * @author DCHIRUM1
 */
import {Component, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, MenuItem, Message, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {ExportPDFService} from '../services/exportPDF/export-pdf.service';
import moment from 'moment';
import {ExcelService} from '../services/excel.service';
import {RfiCostRequestService} from '../services/rfi/rfi-cost-request.service';
import {ManualRfiRequestService} from '../services/rfi/manual-rfi-request.service';
import {Table} from 'primeng/table';

@Component({
	selector: 'app-manual-rfi-request',
	templateUrl: './manual-rfi-request.component.html',
	styleUrls: ['./manual-rfi-request.component.css']
})
export class ManualRfiRequestComponent implements OnInit {
	displayMessage: Message[] = [];
	columns = [];
	rfiHeaderFilter = {};
	rfiRequests: any[] = [];
	rfiRequestsOrg: any[] = [];
	rfiRequestsOrgAll: any[] = [];
	selectedRFI = null;
	selectedPSA = null;
	selectedPartNum = null;
	selectedChangeRequest = null;
	rangeDates: Date[];
	selectedRFIStatus = null;
	selectedStatus = null;
	selectedProgram = null;
	selectedSupplier = null;
	selectedSupplierCode = null;
	selectedCommodity = null;
	selectedBuyerCDSID = null;
	selectedDrCDSID = null;
	selectedCostEstimator = null;
	selectedStat = null;
	programList = [];
	commodityList = [];
	partNumberList = [];
	supplierCodeList = [];
	buyerCDSIDCodeList = [];
	statusList = [];
	buyerSupplier: any;
	filterName: any;
	filterBy: any;
	statusName: any = 'Unread RFIs';
	progSelectDisabled = false;
	dateRangeSelectDisabled = false;
	detailDialog = false;
	dialogDetails = null;
	detailsRfiId = null;
	status;
	back = 'false';
	searchRfi = 'false';
	copyRFIDataDailog = false;
	copyFromFlag;
	fromRFI;
	copyFrom = ['Copy From'];
	selectedCreatedDate = null;
	copyTo = ['Copy To'];
	rfis = [];
	copyRFIProc = false;
	copyToRFIStatus = [1, 2, 5, 6];
	cmfrDCRList = [];
	cmfrDCR;
	loading = true;
	filterOptions: object;
	totalRecords: number;
	@ViewChild('dt', {static: true}) private dataTable: Table;
	private program;
	breadCrumItems: MenuItem[];
	private reSet = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private manualRfiService: ManualRfiRequestService,
		private messageService: MessageService,
		private exportPDFService: ExportPDFService,
		private excelService: ExcelService,
		private rfiCostRequestService: RfiCostRequestService
	) {}

	showDetails(details, rfiId) {
		this.detailDialog = true;
		this.dialogDetails = details;
		this.detailsRfiId = rfiId;
	}

	ngOnInit() {
		this.retriveRouteParameter(false);

		this.columns = [
			{field: 'rfiId', header: 'RFI #'},
			{field: 'psa', header: 'PSA #'},
			{field: 'partNumber', header: 'Part Number/Material Number'},
			{field: 'cmfDCRTitle', header: 'Concern Title'},
			{field: 'rfiModifiedDate', header: 'Updated Date'},
			{field: 'status', header: 'RFI Status'},
			{field: 'leadPrg', header: 'Lead Program'},
			{field: 'supplier', header: 'Supplier'},
			{field: 'supplierCode', header: 'Supplier GSDB Code'},
			//{field: 'changeDesc', header: 'Detailed Description'},
			{field: 'purCommCode', header: 'Material Group Description'},
			{field: 'buyerCode', header: 'Buyer'},
			{field: 'costEstimator', header: 'Cost Estimator'}
			// { field: 'rfiCreatedDate', header: 'ModifiedDate' },
			// { field: 'materialCost', header: 'Material Cost' },
			// { field: 'totalCost', header: 'Gross Price' },
			// { field: 'deltaCost', header: 'Delta(From Previous Gross)' },
			// { field: 'active', header: 'Status' }
		];

		/*if (this.isCostEstimatorRole()) {
			this.columns.push({field: 'costEstimator', header: 'Cost Estimator'});
		}*/

		if (this.isPdEnggRole()) {
			this.columns.push({field: 'drCDSID', header: 'D&R CDSID'});
		}
	}

	isEmpty(str) {
		return !str || 0 === str.length;
	}

	goToBuyer() {
		this.router.navigate(['buyer-supplier/' + this.buyerSupplier]);
	}

	onEdit(rowData) {
		console.log('OnEdit');
		console.log('RfiId' + rowData.rfiId);
		const program = this.filterName === 'program' ? this.filterBy : null;
		this.router.navigate([
			'create-manual-rfi-request/' +
				this.buyerSupplier +
				'/edit/' +
				rowData.rfiId +
				'/' +
				this.status +
				'/' +
				program +
				'/' +
				this.searchRfi +
				'/' +
				this.back +
				'/' +
				'false'
		]);
	}

	createRFI() {
		this.router.navigate([
			'create-manual-rfi-request/' +
				this.buyerSupplier +
				'/create/' +
				null +
				'/SelectRFI/' +
				null +
				'/' +
				this.searchRfi +
				'/' +
				this.back +
				'/' +
				'true'
		]);
	}

	refresh() {
		this.rfiRequests = [];
		this.columns.forEach((key: object) => {
			key['filter'] = '';
		});
		this.dataTable.filters = {};
		this.dataTable.reset();
		//this.clearSearch();
		this.retriveRouteParameter(true);
	}

	onFilterChange(onLoad) {
		if (
			!this.selectedProgram &&
			!this.selectedCommodity &&
			!this.selectedBuyerCDSID &&
			!this.selectedDrCDSID &&
			!this.selectedRFI &&
			!this.selectedChangeRequest &&
			!this.selectedPSA &&
			!this.selectedSupplier &&
			!this.selectedRFIStatus &&
			!this.selectedCostEstimator &&
			!this.selectedSupplierCode &&
			!this.selectedPartNum &&
			(!this.rangeDates || this.rangeDates.length === 0)
		) {
			sessionStorage.removeItem('searchRfi');
			//onLoad = true;
		}

		if (!onLoad) {
			sessionStorage.setItem('searchRfi', 'true');
		}

		this.filterOptions = {
			commodity: this.selectedCommodity,
			program: this.selectedProgram,
			rfi: this.selectedRFI,
			rfiStatus: this.selectedRFIStatus,
			psa: this.selectedPSA,
			supplier: this.selectedSupplier,
			cmfDCR: this.selectedChangeRequest,
			rfiCreatedDate: this.selectedCreatedDate,
			partNumber: this.selectedPartNum,
			supplierCode: this.selectedSupplierCode,
			buyerCDSID: this.selectedBuyerCDSID,
			drCDSID: this.selectedDrCDSID,
			costEstimator: this.selectedCostEstimator,
			status: this.selectedStatus,
			supplierOrBuyer: this.isSupplier()
		};
		this.retriveRfiHeaders(
			this.filterName,
			this.filterBy,
			this.status,
			this.filterOptions
		);
	}

	onFilter(event, dt) {
		const filteredValues = event.filteredValue;
		console.log(filteredValues);
		console.log(dt);
	}

	containsString(mainStr, partStr) {
		let isContains = false;
		mainStr += '';
		if (mainStr && partStr) {
			isContains = mainStr
				.trim()
				.toLowerCase()
				.includes(partStr.trim().toLowerCase());
		}

		return isContains;
	}

	retriveRfiRequests(rfis) {
		return rfis.filter((record) => {
			let isFilter = true;
			if (this.selectedCommodity) {
				isFilter =
					isFilter &&
					(this.containsString(
						record.purCommCode,
						this.selectedCommodity
					) ||
						this.selectedCommodity === 'All Commodities');
				sessionStorage.setItem('commodity', this.selectedCommodity);
			} else {
				sessionStorage.setItem('commodity', null);
			}

			if (this.selectedProgram && this.selectedProgram !== 'null') {
				isFilter =
					isFilter &&
					(this.containsString(
						record.leadPrg,
						this.selectedProgram.trim()
					) ||
						this.selectedProgram === 'All Programs');
				sessionStorage.setItem('program', this.selectedProgram);
			} else {
				sessionStorage.setItem('program', null);
			}

			if (this.selectedRFI && this.selectedRFI !== 'null') {
				isFilter =
					isFilter &&
					this.containsString(record.rfiId, this.selectedRFI.trim());
				sessionStorage.setItem('rfi', this.selectedRFI);
			} else {
				sessionStorage.setItem('rfi', null);
			}

			if (this.selectedRFIStatus && this.selectedRFIStatus !== 'null') {
				const supp = this.isSupplier();

				if (supp) {
					isFilter =
						isFilter &&
						this.containsString(
							record.supplierStatus,
							this.selectedRFIStatus.trim()
						);
				} else {
					isFilter =
						isFilter &&
						this.containsString(
							record.buyerStatus,
							this.selectedRFIStatus.trim()
						);
				}

				sessionStorage.setItem('rfiStatus', this.selectedRFIStatus);
			} else {
				sessionStorage.setItem('rfiStatus', null);
			}

			if (this.selectedPSA && this.selectedPSA !== 'null') {
				isFilter =
					isFilter &&
					this.containsString(record.psa, this.selectedPSA.trim());
				sessionStorage.setItem('psa', this.selectedPSA);
			} else {
				sessionStorage.setItem('psa', null);
			}

			if (this.selectedSupplier && this.selectedSupplier !== 'null') {
				isFilter =
					isFilter &&
					this.containsString(
						record.supplier,
						this.selectedSupplier.trim()
					);
				sessionStorage.setItem('supplier', this.selectedSupplier);
			} else {
				sessionStorage.setItem('supplier', null);
			}

			if (
				this.selectedChangeRequest &&
				this.selectedChangeRequest !== 'null'
			) {
				isFilter =
					isFilter &&
					this.containsString(
						record.cmfDCRTitle,
						this.selectedChangeRequest.trim()
					);
				sessionStorage.setItem(
					'cmfDCRTitle',
					this.selectedChangeRequest
				);
			} else {
				sessionStorage.setItem('cmfDCRTitle', null);
			}
			if (
				this.selectedCreatedDate &&
				this.selectedCreatedDate !== 'null'
			) {
				isFilter =
					isFilter &&
					this.containsString(
						record.rfiCreatedDate,
						this.selectedCreatedDate.trim()
					);
				sessionStorage.setItem(
					'rfiCreatedDate',
					this.selectedCreatedDate
				);
			} else {
				sessionStorage.setItem('rfiCreatedDate', null);
			}
			if (this.selectedPartNum) {
				const desc = this.selectedPartNum; //this.getPartNumber(this.selectedPartNum);
				isFilter =
					isFilter &&
					(this.containsString(record.partNumber, desc) ||
						desc === 'All Part Numbers');
				sessionStorage.setItem('partNumber', desc);
			} else {
				sessionStorage.setItem('partNumber', null);
			}

			if (this.selectedSupplierCode) {
				isFilter =
					isFilter &&
					(this.containsString(
						record.siteCode,
						this.selectedSupplierCode
					) ||
						this.selectedSupplierCode === 'All Supliers');
				sessionStorage.setItem('siteCode', this.selectedSupplierCode);
			} else {
				sessionStorage.setItem('siteCode', null);
			}

			if (this.selectedBuyerCDSID) {
				isFilter =
					isFilter &&
					(this.containsString(
						record.buyerCode,
						this.selectedBuyerCDSID
					) ||
						this.selectedBuyerCDSID === 'All Buyers');
				sessionStorage.setItem('buyer', this.selectedBuyerCDSID);
			} else {
				sessionStorage.setItem('buyer', null);
			}

			if (this.selectedDrCDSID) {
				isFilter =
					isFilter &&
					this.containsString(record.drCDSID, this.selectedDrCDSID);
				sessionStorage.setItem('pdEngg', this.selectedDrCDSID);
			} else {
				sessionStorage.setItem('pdEngg', null);
			}

			if (this.selectedCostEstimator) {
				isFilter =
					isFilter &&
					this.containsString(
						record.costEstimator,
						this.selectedCostEstimator
					);
				sessionStorage.setItem('costEst', this.selectedCostEstimator);
			} else {
				sessionStorage.setItem('costEst', null);
			}

			if (this.selectedStatus) {
				isFilter = isFilter && record.active === this.selectedStatus;
			}

			if (this.rangeDates && this.rangeDates.length > 0) {
				sessionStorage.setItem(
					'rangeDates',
					JSON.stringify(this.rangeDates)
				);
				const startDate = new Date(this.rangeDates[0]);
				startDate.setHours(0, 0, 0, 0);

				isFilter =
					isFilter &&
					new Date(record.rfiCreatedDate).getTime() >
						startDate.getTime();
				if (this.rangeDates.length === 2 && !this.rangeDates[1]) {
					const endDate = new Date(this.rangeDates[0]);
					endDate.setHours(23, 59, 59, 999);
					isFilter =
						isFilter &&
						new Date(record.rfiCreatedDate).getTime() <
							endDate.getTime();
				}

				if (this.rangeDates.length === 2 && this.rangeDates[1]) {
					const endDate = new Date(this.rangeDates[1]);
					endDate.setHours(23, 59, 59, 999);
					isFilter =
						isFilter &&
						new Date(record.rfiCreatedDate).getTime() <
							endDate.getTime();
				}
			} else {
				sessionStorage.setItem('rangeDates', null);
			}

			if (this.selectedStat) {
				if (this.isSupplier()) {
					isFilter =
						isFilter &&
						record.supplierStatus ===
							this.selectedStat.supplierStatus;
				} else {
					isFilter =
						isFilter &&
						record.buyerStatus === this.selectedStat.buyerStatus;
				}
			}

			if (this.copyRFIProc) {
				if (this.isSupplier()) {
					const fromRFI = this.copyFrom[0];
					const rfiId = record.rfiId;
					isFilter =
						isFilter &&
						this.copyToRFIStatus.includes(record.statusId) &&
						fromRFI !== rfiId;
				}
			}

			return isFilter;
		});
	}

	clearSearch() {
		this.searchRfi = 'false';
		sessionStorage.removeItem('searchRfi');
		this.selectedCommodity = null;

		if (!this.progSelectDisabled) {
			this.selectedProgram = null;
		}
		this.selectedPartNum = null;
		this.selectedSupplierCode = null;
		this.selectedBuyerCDSID = null;
		this.selectedDrCDSID = null;
		this.selectedCostEstimator = null;
		this.selectedStatus = null;
		this.selectedPSA = null;
		this.selectedRFI = null;
		this.selectedRFIStatus = null;
		this.selectedStatus = null;
		this.selectedSupplier = null;
		this.selectedChangeRequest = null;

		if (!this.dateRangeSelectDisabled) {
			this.rangeDates = null;
		}

		sessionStorage.setItem('program', null);
		sessionStorage.setItem('commodity', null);
		sessionStorage.setItem('costEst', null);
		sessionStorage.setItem('rfi', null);
		sessionStorage.setItem('rfiStatus', null);
		sessionStorage.setItem('psa', null);
		sessionStorage.setItem('supplier', null);
		sessionStorage.setItem('cmfDCRTitle', null);
		sessionStorage.setItem('partNumber', null);
		sessionStorage.setItem('siteCode', null);
		sessionStorage.setItem('buyer', null);
		sessionStorage.setItem('pdEngg', null);
		sessionStorage.setItem('rangeDates', null);

		this.filterOptions = {};

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			this.searchRfi === 'true' &&
			!this.isBuyerRole() &&
			!this.isPdEnggRole() &&
			!this.isCostEstimatorRole() &&
			!apsRoles.includes('EDCM_UPDATE')
		) {
			this.rfiRequestsOrg = [];
		}

		this.onFilterChange(!apsRoles.includes('EDCM_UPDATE'));
	}

	onPrint() {
		const tableContent = [];
		const headerRow = [];
		this.columns.forEach((column) => {
			headerRow.push({text: column.header, bold: true, width: '20px'});
		});

		tableContent.push(headerRow);
		this.rfiRequests.forEach((rfiRequest) => {
			tableContent.push([
				{text: rfiRequest.rfiId},
				{text: rfiRequest.partNumber},
				{text: rfiRequest.leadPrg},
				{text: rfiRequest.buyerCode},
				{text: rfiRequest.supplierCod},
				{text: rfiRequest.supplierCode},
				{text: rfiRequest.purCommCode},
				{text: rfiRequest.cmfDCRTitle},
				{text: rfiRequest.changeDesc}
			]);
		});

		this.exportPDFService.exportPDF(tableContent, 'RfiRequests.pdf');
	}

	exportAsXLSX() {
		const rfiExports = [];
		this.rfiRequests.forEach((rfi) => {
			const rfiExport = {};
			rfiExport['RFI #'] = rfi.rfiId;
			rfiExport['Part #'] = rfi.partNumber;
			rfiExport['Lead Program'] = rfi.leadPrg;
			rfiExport['Buyer'] = rfi.buyerCode;
			rfiExport['Suppiler'] = rfi.supplier;
			rfiExport['Site Code'] = rfi.supplierCode;
			rfiExport['Material Group'] =
				rfi.purCommCode &&
				rfi.purCommCode.includes('COMMODITY CODE ERROR')
					? ''
					: rfi.purCommCode;
			rfiExport['Change Request Title'] = rfi.cmfDCRTitle;
			rfiExport['Detailed Description of Change'] = rfi.changeDesc;
			rfiExport['RFI Status'] = rfi.status;
			rfiExport['Created Date'] = rfi.rfiCreatedDate
				? moment(rfi.rfiCreatedDate).format('MM/DD/YYYY')
				: rfi.rfiCreatedDate;

			rfiExports.push(rfiExport);
		});

		const fileName = this.isSupplier() ? 'SupplierData' : 'BuyerData';
		this.excelService.exportAsExcelFile(rfiExports, fileName);
	}

	exportAllAsXLSX() {
		const supp = this.isSupplier();
		let userId = supp
			? sessionStorage.getItem('emailAddress')
			: sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (this.isEDCMViewOnlyRole()) {
			userId = 'EDCM_VIEW_ONLY';
		}

		this.manualRfiService
			.findAllRfiData(supp, userId)
			.subscribe((response) => {
				const rfiExports = [];

				response.forEach((rfi) => {
					const rfiExport = {};
					rfiExport['RFI #'] = rfi.rfiNo;
					rfiExport['RFI Created Date'] = rfi.creationDate
						? moment(rfi.creationDate).format('MM/DD/YYYY HH:MM')
						: rfi.creationDate;
					rfiExport['RFI Status'] = rfi.curStatus;
					rfiExport['PSA #'] = rfi.psa;
					rfiExport['CMF / DCR #'] = rfi.cmfDCR;
					rfiExport['CMF / DCR Title'] = rfi.cmfDCRTitle;
					rfiExport['CMF / DCR Change Description'] = rfi.cmfDCRDesc;
					rfiExport['D&R CDSID'] = rfi.drEngr;
					rfiExport['PART NUMBER/MATERIAL NUMBER'] =
						rfi.pprefix.trim() +
						'-' +
						rfi.pbase.trim() +
						'-' +
						rfi.psuffix.trim();

					if (!supp) {
						rfiExport['Initial Piece Price Target'] = rfi.mcosTgt;
						rfiExport['Initial Piece Price Target Currency'] =
							rfi.mtCur;
						rfiExport['Initial Production Tooling Target'] =
							rfi.tcosTgt;
						rfiExport[
							'Initial Production Tooling Target Currency'
						] = rfi.ttCur;
						rfiExport['Piece Price Status'] = rfi.engemSts;
						rfiExport['Piece Price Status Currency'] = rfi.engemCur;
						rfiExport['Production Tooling Status'] = rfi.tcosSts;
						rfiExport['Production Tooling Status Currency'] =
							rfi.engetCur;
						rfiExport['Piece Price Estimate'] = rfi.mcosCts;
						rfiExport['Piece Price Estimate Currency'] = rfi.mstCur;
						rfiExport['Production Tooling Estimate'] = rfi.engetSts;
						rfiExport['Production Tooling Estimate Currency'] =
							rfi.tstCur;
					}

					rfiExport['Lead Program'] = rfi.program;
					rfiExport['MP&L Plant Code'] = rfi.plant;
					rfiExport['Part Name'] = rfi.partName;
					rfiExport['Material Group/Commodity Code'] = rfi.commCD;
					//rfiExport['PURCHASE COMM'] = rfi.purComm;
					rfiExport['Buyer CDSID'] = rfi.buyer;
					rfiExport['Supplier Email'] = rfi.supplierEmail;
					//rfiExport['PGROUP'] = rfi.purGroup;
					rfiExport['Supplier GSDB Code'] = rfi.supplier;
					//rfiExport['SUPPLIER CONTACT'] = rfi.scontact;
					rfiExport['Supplier Comments'] = rfi.scomment;
					rfiExport['Buyer Comments'] = rfi.buyerComment;
					rfiExport['PD Comments'] = rfi.pdComment;
					rfiExport['Lump Sum ED&T'] = rfi.lumpSum;
					rfiExport['Lump Sum ED&T Value Includes Financing'] =
						rfi.lumpSumFIN;
					rfiExport['Row TYPE'] = rfi.rowType;
					rfiExport['Cost Description'] = rfi.costDesc;
					rfiExport['Amount '] = rfi.amountA;
					rfiExport['ResponseCurrency '] = rfi.responsecur;

					if (this.isRecordFilter(rfi)) {
						rfiExports.push(rfiExport);
					}
				});

				this.excelService.exportAsExcelFile(rfiExports, 'RFI_Data');
			});
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer')
		);
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Costestimator')
		);
	}

	isEDCMViewOnlyRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('EDCM_VIEW_ONLY');
	}

	isJVRoles() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('JV Engineer') ||
			apsRoles.includes('JV Buyer') ||
			apsRoles.includes('JV Costestimator') ||
			apsRoles.includes('PD_Engineer')
		);
	}

	isAdminRole(apsRoles) {
		return (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer')
		);
	}

	public copRFIMenue(state) {
		this.copyRFIDataDailog = state;
		if (!state) {
			this.clearCopyRFI();
		}
	}

	public showFromToCopySelection() {
		return this.copyFromFlag === 'true' || this.copyFromFlag === 'false';
	}

	onCMFDCRSelect() {
		this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrg);
		this.rfiRequests = this.rfiRequests.filter(
			(res) => res.cmfDCR.trim() === this.cmfrDCR.value
		);
	}

	public onRowSelect(event) {
		if (this.copyFromFlag === 'true') {
			this.copyFrom = [];
			this.copyFrom.push(event.data.rfiId);
		}

		if (this.copyFromFlag === 'false') {
			this.copyTo = [];
			this.rfis.forEach((data) => {
				this.copyTo.push(data.rfiId);
			});
		}
	}

	public clearRfiSelection(toRfi) {
		this.rfis = [];
		if (toRfi) {
			this.copyRFIProc = true;
			const filterCha =
				sessionStorage.getItem('searchRfi') === 'true' ? false : true;
			this.onFilterChange(filterCha);
			this.onCMFDCRSelect();
		}
	}

	public isFromToRFIAvailable() {
		return this.copyFrom[0] !== 'Copy From' && this.copyTo[0] !== 'Copy To';
	}

	public copRFIs() {
		const fromRFI = this.copyFrom[0];
		const toRFIS = this.copyTo;

		this.rfiCostRequestService
			.copyRFICosts(fromRFI, toRFIS)
			.subscribe((response) => {
				this.messageService.add({
					life: 300000,
					severity: 'success',
					summary: 'Success',
					detail: 'All RFI costs has been copied successfully to target RFIs'
				});
				console.log(response);
			});
	}

	private isSupplier() {
		return this.buyerSupplier.toLowerCase() === 'supplier';
	}

	isBuyer() {
		return this.buyerSupplier.toLowerCase() === 'buyer';
	}

	private isRecordFilter(record): boolean {
		let isFilter = true;

		if (this.selectedRFI) {
			isFilter =
				isFilter && this.containsString(record.rfiNo, this.selectedRFI);
		}

		if (this.selectedPSA) {
			isFilter =
				isFilter && this.containsString(record.psa, this.selectedPSA);
		}

		if (this.selectedPartNum) {
			isFilter =
				isFilter &&
				this.containsString(
					record.pprefix.trim() +
						'-' +
						record.pbase.trim() +
						'-' +
						record.psuffix.trim(),
					this.selectedPartNum
				);
		}

		if (this.selectedChangeRequest) {
			isFilter =
				isFilter &&
				this.containsString(
					record.cmfDCRTitle,
					this.selectedChangeRequest
				);
		}
		if (this.rangeDates && this.rangeDates.length > 0) {
			const startDate = this.rangeDates[0];
			startDate.setHours(0, 0, 0, 0);

			isFilter =
				isFilter &&
				new Date(record.creationDate).getTime() > startDate.getTime();
			if (this.rangeDates.length === 2 && !this.rangeDates[1]) {
				const endDate = new Date(this.rangeDates[0]);
				endDate.setHours(23, 59, 59, 999);
				isFilter =
					isFilter &&
					new Date(record.creationDate).getTime() < endDate.getTime();
			}

			if (this.rangeDates.length === 2 && this.rangeDates[1]) {
				const endDate = new Date(this.rangeDates[1]);
				endDate.setHours(23, 59, 59, 999);
				isFilter =
					isFilter &&
					new Date(record.creationDate).getTime() < endDate.getTime();
			}
		}

		if (this.selectedProgram) {
			isFilter =
				isFilter &&
				this.containsString(record.program, this.selectedProgram);
		}

		if (this.selectedSupplier) {
			isFilter =
				isFilter &&
				this.containsString(
					record.supplierEmail,
					this.selectedSupplier
				);
		}

		if (this.selectedSupplierCode) {
			isFilter =
				isFilter &&
				this.containsString(record.supplier, this.selectedSupplierCode);
		}

		if (this.selectedCommodity) {
			isFilter =
				isFilter &&
				this.containsString(record.purComm, this.selectedCommodity);
		}

		if (this.selectedBuyerCDSID) {
			isFilter =
				isFilter &&
				this.containsString(record.buyer, this.selectedBuyerCDSID);
		}

		if (this.selectedDrCDSID) {
			isFilter =
				isFilter &&
				this.containsString(record.drCDSID, this.selectedDrCDSID);
		}

		if (this.selectedCostEstimator) {
			isFilter =
				isFilter &&
				this.containsString(
					record.costEstimator,
					this.selectedCostEstimator
				);
		}

		if (this.selectedStat) {
			isFilter =
				isFilter &&
				(record.curStatus === this.selectedStat.supplierStatus ||
					record.curStatus === this.selectedStat.buyerStatus);
		}

		if (
			this.status &&
			this.status !== 'SupplierSelectRFI' &&
			this.status !== 'BuyerSelectRFI' &&
			this.status !== 'null'
		) {
			isFilter = isFilter && record.rfiStatus === this.status;
		}

		if (this.status === 'null') {
			if (this.isSupplier()) {
				return (
					record.curStatus.toUpperCase() ===
						'Accepted'.toUpperCase() ||
					record.curStatus.toUpperCase() ===
						'Rejected'.toUpperCase() ||
					record.curStatus.toUpperCase() ===
						'Cancelled'.toUpperCase() ||
					record.curStatus.toUpperCase() === 'Duplicate'.toUpperCase()
				);
			}
		}

		return isFilter;
	}

	private getPartNumber(partNum) {
		const partNumer = partNum.partIdentity
			? partNum.partIdentity.partPrefix.trim() +
				'-' +
				partNum.partIdentity.partBase.trim() +
				'-' +
				partNum.partIdentity.partSuffix.trim()
			: 'All Part Numbers';

		return partNumer;
	}

	retriveRfiHeaders(
		filterName,
		filterBy,
		status,
		filterOptions,
		event?: LazyLoadEvent
	) {
		const supp = this.isSupplier();
		let userId = supp
			? sessionStorage.getItem('emailAddress')
			: sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (this.isEDCMViewOnlyRole()) {
			userId = 'EDCM_VIEW_ONLY';
		}

		let role = '';

		if (this.isBuyerRole()) {
			role = 'Buyer';
		}

		if (apsRoles.includes('EDCM_UPDATE')) {
			role = 'EDCM_UPDATE';
		}

		if (this.isPdEnggRole()) {
			role = 'PD_Engineer';
		}

		if (this.isCostEstimatorRole()) {
			role = 'CostEstimator';
		}

		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			this.isEDCMViewOnlyRole()
		) {
			role = 'eDCM_IT_ADMIN';
		}

		this.manualRfiService
			.findAllCMFDCR(supp, userId)
			.subscribe((result) => {
				this.cmfrDCRList = result;
			});

		this.loading = true;
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 0;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;
		}

		this.prePopulateFilter(filterName, filterBy, event);
		filterOptions = this.filterOptions;

		this.manualRfiService
			.findAll(
				supp,
				userId,
				role,
				page,
				size,
				sortColumn,
				sortOrder,
				filterOptions
			)
			.subscribe((result) => {
				this.loading = false;
				this.rfiRequestsOrg = result['user'].map((rfi) => {
					rfi.purCommCode =
						supp &&
						rfi.purCommCode &&
						rfi.purCommCode.includes('COMMODITY CODE ERROR')
							? ''
							: rfi.purCommCode;
					return rfi;
				});

				let dataFetchFor = 'all';
				if (supp) {
					dataFetchFor = 'user';
				}

				this.totalRecords = result.totalRecordsUser;
				if (sessionStorage.getItem('searchRfi') === 'true') {
					this.totalRecords = result.totalRecordsAll;
				}

				if (this.searchRfi === 'true' && !this.isBuyerRole()) {
					this.rfiRequestsOrg = [];
				}

				this.rfiRequestsOrgAll = result[dataFetchFor].map((rfi) => {
					rfi.purCommCode =
						supp &&
						rfi.purCommCode &&
						rfi.purCommCode.includes('COMMODITY CODE ERROR')
							? ''
							: rfi.purCommCode;
					return rfi;
				});

				if (
					(status && status !== 'null' && filterName === 'program') ||
					filterName === 'dateRange'
				) {
					this.rfiRequestsOrg = this.rfiRequestsOrg.filter(
						(res) => res.isActive === status
					);
					this.rfiRequestsOrgAll = this.rfiRequestsOrgAll.filter(
						(res) => res.isActive === status
					);
				}
				if (this.isSupplier()) {
					this.rfiRequestsOrg.forEach((res) => {
						res['status'] = res.supplierStatus;
					});

					this.rfiRequestsOrgAll.forEach((res) => {
						res['status'] = res.supplierStatus;
					});
				} else {
					this.rfiRequestsOrg.forEach((res) => {
						res['status'] = res.buyerStatus;
					});

					this.rfiRequestsOrgAll.forEach((res) => {
						res['status'] = res.buyerStatus;
					});
				}

				const searchRfi =
					sessionStorage.getItem('searchRfi') !== null
						? sessionStorage.getItem('searchRfi')
						: this.back;

				if (
					searchRfi === 'true' &&
					!this.isBuyerRole() &&
					!this.isPdEnggRole() &&
					!this.isCostEstimatorRole() &&
					!this.isAdminRole(apsRoles)
				) {
					//this.rfiRequestsOrg = [];
				}

				const filterCha =
					sessionStorage.getItem('searchRfi') === 'true'
						? false
						: true;
				//this.onFilterChange(filterCha);
				this.updateRFIDataList(filterCha);
				this.updateStatusString();
			});
	}

	private prePopulateFilter(filterName, filterBy, event?) {
		if (filterName === 'program' || filterName === 'dateRange') {
			if (filterName === 'program') {
				//this.selectedProgram = filterBy;//this.programList.filter(d => d.name === filterBy)[0];
				this.selectedProgram =
					filterBy && filterBy !== 'null' ? filterBy : null;
				if (filterBy && filterBy !== 'null') {
					this.progSelectDisabled = true;
				}
			}

			if (filterName === 'dateRange') {
				this.rangeDates = [];
				let strArr = [];
				if (filterBy.includes('-')) {
					strArr = filterBy.split('-');
				} else {
					strArr = filterBy.split('>');
					strArr[0] = 15;
					strArr[1] = 1000;
				}

				const enddate = moment().hours(0).minutes(0).seconds(0);
				enddate.subtract(strArr[0], 'days');

				if (!this.isEmpty(strArr[1])) {
					const startdate = moment().hours(0).minutes(0).seconds(0);
					startdate.subtract(strArr[1], 'days');
					this.rangeDates.push(startdate.toDate());
				}

				this.rangeDates.push(enddate.toDate());

				console.log(
					'startdate : ' +
						this.rangeDates[0] +
						' : enddate : ' +
						this.rangeDates[1]
				);
				this.dateRangeSelectDisabled = true;
			}
		}

		let startDate = '';
		let endDate = '';
		if (this.rangeDates) {
			if (this.rangeDates.length > 0 && this.rangeDates[0]) {
				const date = new Date(this.rangeDates[0]);
				const month = date.getMonth() + 1;
				startDate = `${date.getDate()}-${month > 9 ? month : '0' + month}-${date.getFullYear()}`;
			}
			if (this.rangeDates.length > 1 && this.rangeDates[1]) {
				const date = new Date(this.rangeDates[1]);
				const month = date.getMonth() + 1;
				endDate = `${date.getDate()}-${month > 9 ? month : '0' + month}-${date.getFullYear()}`;
			}
		}

		// const program = sessionStorage.getItem('program');
		// if (!this.selectedProgram && program && 'null' !== program) {
		// 	this.selectedProgram = program;
		// }

		// const commodity = sessionStorage.getItem('commodity');
		// if (!this.selectedCommodity && commodity && 'null' !== commodity) {
		// 	this.selectedCommodity = commodity;
		// }

		// const buyer = sessionStorage.getItem('buyer');
		// if (!this.selectedBuyerCDSID && buyer && 'null' !== buyer) {
		// 	this.selectedBuyerCDSID = buyer;
		// }

		// const pdEngg = sessionStorage.getItem('pdEngg');
		// if (!this.selectedDrCDSID && pdEngg && 'null' !== pdEngg) {
		// 	this.selectedDrCDSID = pdEngg;
		// }

		// const costEstimator = sessionStorage.getItem('costEst');
		// if (!this.selectedCostEstimator && costEstimator && 'null' !== costEstimator) {
		// 	this.selectedCostEstimator = costEstimator;
		// }

		// const siteCode = sessionStorage.getItem('siteCode');
		// if (!this.selectedSupplierCode && siteCode && 'null' !== siteCode) {
		// 	this.selectedSupplierCode = siteCode;
		// }

		// const partNumber = sessionStorage.getItem('partNumber');
		// if (!this.selectedPartNum && partNumber && 'null' !== partNumber) {
		// 	this.selectedPartNum = partNumber;
		// }

		this.filterOptions = {
			commodity: this.selectedCommodity,
			program: this.selectedProgram,
			rfi: this.selectedRFI,
			rfiStatus: this.selectedRFIStatus,
			psa: this.selectedPSA,
			supplier: this.selectedSupplier,
			cmfDCRTitle: this.selectedChangeRequest,
			rfiCreatedDate: this.selectedCreatedDate,
			partNumber: this.selectedPartNum,
			supplierCode: this.selectedSupplierCode,
			buyerCDSID: this.selectedBuyerCDSID,
			drCDSID: this.selectedDrCDSID,
			costEstimator: this.selectedCostEstimator,
			status: this.selectedStatus,
			endDate,
			startDate,
			supplierOrBuyer: this.isSupplier()
		};

		if (event && event.filters && Object.keys(event.filters).length !== 0) {
			this.filterOptions['rfi'] = event.filters.rfiId
				? event.filters.rfiId.value
				: null;
			this.filterOptions['psa'] = event.filters.psa
				? event.filters.psa.value
				: null;
			this.filterOptions['partNumber'] = event.filters.partNumber
				? event.filters.partNumber.value
				: null;
			this.filterOptions['cmfDCR'] = event.filters.cmfDCR
				? event.filters.cmfDCR.value
				: null;
			this.filterOptions['cmfDCRTitle'] = event.filters.cmfDCRTitle
				? event.filters.cmfDCRTitle.value
				: null;
			this.filterOptions['rfiModifiedDate'] = event.filters
				.rfiModifiedDate
				? event.filters.rfiModifiedDate.value
				: null;
			this.filterOptions['rfiStatus'] = event.filters.status
				? event.filters.status.value
				: null;
			this.filterOptions['program'] = event.filters.leadPrg
				? event.filters.leadPrg.value
				: null;
			this.filterOptions['supplier'] = event.filters.supplier
				? event.filters.supplier.value
				: null;
			this.filterOptions['supplierCode'] = event.filters.supplierCode
				? event.filters.supplierCode.value
				: null;
			this.filterOptions['commodity'] = event.filters.purCommCode
				? event.filters.purCommCode.value
				: null;
			this.filterOptions['buyerCDSID'] = event.filters.buyerCode
				? event.filters.buyerCode.value
				: null;
			this.filterOptions['rfiCreatedDate'] = event.filters.rfiCreatedDate
				? event.filters.rfiCreatedDate.value
				: null;
			this.filterOptions['costEstimator'] = event.filters.costEstimator
				? event.filters.costEstimator.value
				: null;
			this.filterOptions['supplierOrBuyer'] = this.isSupplier();
		}

		if (
			event === undefined &&
			this.dataTable.filters &&
			Object.keys(this.dataTable.filters).length !== 0
		) {
			if (this.dataTable.filters.rfiId) {
				this.filterOptions['rfi'] = (
					this.dataTable.filters.rfiId as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.psa) {
				this.filterOptions['psa'] = (
					this.dataTable.filters.psa as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.partNumber) {
				this.filterOptions['partNumber'] = (
					this.dataTable.filters.partNumber as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.cmfDCR) {
				this.filterOptions['cmfDCR'] = (
					this.dataTable.filters.cmfDCR as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.cmfDCRTitle) {
				this.filterOptions['cmfDCRTitle'] = (
					this.dataTable.filters.cmfDCRTitle as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.rfiModifiedDate) {
				this.filterOptions['rfiModifiedDate'] = (
					this.dataTable.filters.rfiModifiedDate as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.status) {
				this.filterOptions['rfiStatus'] = (
					this.dataTable.filters.status as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.leadPrg) {
				this.filterOptions['program'] = (
					this.dataTable.filters.leadPrg as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.supplier) {
				this.filterOptions['supplier'] = (
					this.dataTable.filters.supplier as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.supplierCode) {
				this.filterOptions['supplierCode'] = (
					this.dataTable.filters.supplierCode as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.purCommCode) {
				this.filterOptions['commodity'] = (
					this.dataTable.filters.purCommCode as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.rfiCreatedDate) {
				this.filterOptions['rfiCreatedDate'] = (
					this.dataTable.filters.rfiCreatedDate as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.costEstimator) {
				this.filterOptions['costEstimator'] = (
					this.dataTable.filters.costEstimator as HTMLInputElement
				).value;
			}
			if (this.dataTable.filters.buyerCode) {
				this.filterOptions['buyerCDSID'] = (
					this.dataTable.filters.buyerCode as HTMLInputElement
				).value;
			}
			this.filterOptions['supplierOrBuyer'] = this.isSupplier();
		}
	}

	private updateRFIDataList(onLoad) {
		if (
			!this.selectedProgram &&
			!this.selectedCommodity &&
			!this.selectedBuyerCDSID &&
			!this.selectedRFI &&
			!this.selectedChangeRequest &&
			!this.selectedPSA &&
			!this.selectedSupplier &&
			!this.selectedRFIStatus &&
			!this.selectedDrCDSID &&
			!this.selectedCostEstimator &&
			!this.selectedSupplierCode &&
			!this.selectedPartNum &&
			(!this.rangeDates || this.rangeDates.length === 0)
		) {
			sessionStorage.removeItem('searchRfi');
			onLoad = true;
		}

		if (onLoad) {
			this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrg);
		} else {
			//this.searchRfi = 'true';
			sessionStorage.setItem('searchRfi', 'true');
			this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrgAll);
		}
	}

	private updateStatusString() {
		const buyerSupplier =
			this.buyerSupplier.toLowerCase() === 'buyer'
				? 'Buyer Home Page'
				: 'Supplier Home Page';
		const buyerSupplierUrl =
			this.buyerSupplier.toLowerCase() === 'buyer' ? 'buyer' : 'supplier';
		//let statusString = this.status === 'U' ? 'Unread RFIS' : (this.status === 'I' ? 'In Progress RFIs' : 'Completed RFIs');
		//const program = this.program;

		// if (program && program !== 'null') {
		// 	statusString = this.status === 'U' ? 'PIE Unread RFIS' : (this.status === 'I' ? 'PIE In Progress RFIs' : 'PIE Completed RFIs');
		// }

		// let statusUrl = '#/manual-rfi-request/' + this.buyerSupplier + '/program/' + program + '/' + this.status;
		// if (program === 'all') {
		// 	statusString = 'Manual RFIs';
		// 	// tslint:disable-next-line:no-shadowed-variable
		// 	const supp = this.buyerSupplier.toLowerCase() === 'buyer' ? 'BuyerSelectRFI' : 'SupplierSelectRFI';
		// 	statusUrl = '#/manual-rfi-request/' + this.buyerSupplier + '/all/all/' + supp + '/false/false';
		// }
		this.breadCrumItems = [
			{
				label: buyerSupplier,
				url: '#/buyer-supplier/' + buyerSupplierUrl,
				target: '_self'
			},
			{label: 'Manual RFI', target: '_self'}
		];

		// if (program && program !== 'null' && program !== 'all') {
		// 	if (statusString === 'PIE Completed RFIs') {
		// 		this.breadCrumItems.push({label: this.status});
		// 		this.breadCrumItems.push({label: program});
		// 	} else {
		// 		this.breadCrumItems.push({label: program});
		// 	}
		// }
	}

	private retriveRouteParameter(refresh) {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.buyerSupplier = params.get('buyerSupplier');
			this.filterName = params.get('filterName');
			this.filterBy = params.get('filterBy');
			this.status = params.get('status');
			this.back = params.get('back');
			this.searchRfi = params.get('searchRfi');

			if (this.status) {
				if (this.status === 'U') {
					this.statusName = 'Unread';
				} else if (this.status === 'I') {
					this.statusName = 'In Progress';
				} else {
					this.statusName = 'Completed';
					if (
						this.status === 'BuyerSelectRFI' ||
						this.status === 'SupplierSelectRFI' ||
						this.status === 'undefined'
					) {
						this.statusName = 'RFI FILTER';
					}
				}
			} else {
				this.statusName = this.filterBy;
			}

			if (this.filterName === 'dateRange') {
				this.statusName =
					this.statusName + ' (' + this.filterBy + ' days older)';
			}

			if (this.filterBy) {
				this.program = this.filterBy;
			}
			console.log('Program : ' + sessionStorage.getItem('program'));

			const filetrOptions = {};
			this.updateSelectedValue(refresh);
			this.retriveRfiHeaders(
				this.filterName,
				this.filterBy,
				this.status,
				filetrOptions
			);
		});
	}

	private isNotBlank(key) {
		return (
			sessionStorage.getItem(key) &&
			sessionStorage.getItem(key) !== 'null'
		);
	}

	private updateSelectedValue(refresh) {
		if (this.back === 'true') {
			if (this.isNotBlank('rfi')) {
				this.selectedRFI = sessionStorage.getItem('rfi');
			}
			if (this.isNotBlank('psa')) {
				this.selectedPSA = sessionStorage.getItem('psa');
			}
			if (this.isNotBlank('partNumber')) {
				this.selectedPartNum = sessionStorage.getItem('partNumber');
			}
			if (this.isNotBlank('program')) {
				this.selectedProgram = sessionStorage.getItem('program');
			}
			if (this.isNotBlank('commodity')) {
				this.selectedCommodity = sessionStorage.getItem('commodity');
			}
			if (this.isNotBlank('costEst')) {
				this.selectedCostEstimator = sessionStorage.getItem('costEst');
			}
			if (this.isNotBlank('rfiStatus')) {
				this.selectedRFIStatus = sessionStorage.getItem('rfiStatus');
			}
			if (this.isNotBlank('supplier')) {
				this.selectedSupplier = sessionStorage.getItem('supplier');
			}
			if (this.isNotBlank('cmfDCRTitle')) {
				this.selectedChangeRequest =
					sessionStorage.getItem('cmfDCRTitle');
			}
			if (this.isNotBlank('siteCode')) {
				this.selectedSupplierCode = sessionStorage.getItem('siteCode');
			}
			if (this.isNotBlank('buyer')) {
				this.selectedBuyerCDSID = sessionStorage.getItem('buyer');
			}
			if (this.isNotBlank('pdEngg')) {
				this.selectedDrCDSID = sessionStorage.getItem('pdEngg');
			}
			if (this.isNotBlank('rangeDates')) {
				const ranges = JSON.parse(sessionStorage.getItem('rangeDates'));
				if (ranges && ranges.length > 0 && ranges[0] != null) {
					this.rangeDates = [];
					this.rangeDates[0] = new Date(ranges[0]);
					if (ranges.length > 1 && ranges[1] != null) {
						this.rangeDates[1] = new Date(ranges[1]);
					}
				}
			}
		} else if (!refresh) {
			sessionStorage.setItem('program', null);
			sessionStorage.setItem('commodity', null);
			sessionStorage.setItem('costEst', null);
			sessionStorage.setItem('rfi', null);
			sessionStorage.setItem('rfiStatus', null);
			sessionStorage.setItem('psa', null);
			sessionStorage.setItem('supplier', null);
			sessionStorage.setItem('cmfDCRTitle', null);
			sessionStorage.setItem('partNumber', null);
			sessionStorage.setItem('siteCode', null);
			sessionStorage.setItem('buyer', null);
			sessionStorage.setItem('pdEngg', null);
			sessionStorage.setItem('rangeDates', null);
			this.selectedPartNum = null;
			this.selectedSupplierCode = null;
			this.selectedBuyerCDSID = null;
			this.selectedDrCDSID = null;
			this.selectedCostEstimator = null;
			this.selectedStatus = null;
			this.selectedPSA = null;
			this.selectedRFI = null;
			this.selectedRFIStatus = null;
			this.selectedStatus = null;
			this.selectedSupplier = null;
			this.selectedChangeRequest = null;
			this.rangeDates = null;
		}
	}

	private clearCopyRFI() {
		this.copyFromFlag = null;
		this.copyFrom = ['Copy From'];
		this.copyTo = ['Copy To'];
		this.rfis = [];
		this.cmfrDCR = null;
		this.copyRFIProc = false;
		const filterCha =
			sessionStorage.getItem('searchRfi') === 'true' ? false : true;
		//this.onFilterChange(filterCha);
		this.updateRFIDataList(filterCha);
	}
}
